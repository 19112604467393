import {
  Banner,
  Box,
  Container,
  Dialog,
  Heading,
  Helmet,
  Interfaces,
  Link,
  LinkButton,
  NavigationTiles,
  PdfLink,
  Row,
  Section,
  Story,
  TickListItem,
  routesObject
} from "@life-without-barriers/react-components"

import { DisabilityForm } from "@life-without-barriers/shared-contact-form"

import {
  HealthAndWellbeingNoIcon,
  SupportCoordinationNoIcon,
  SupportedIndependentLivingNoIcon
} from "../../../components/disability/RelatedPagesContent"

import { IGatsbyImageData } from "gatsby-plugin-image"
import Layout from "../../../components/disability/Layout"
import LocationModal from "../../../components/disability/LocationModal"
import React from "react"
import RelatedPagesHeading from "../../../components/disability/RelatedPagesHeading"
import { Site } from "@life-without-barriers/gatsby-common"
import { graphql } from "gatsby"
import styled from "styled-components"

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
    lifestyleSupportsEasyEnglish: Interfaces.FileProps
    storyMattBarista: IGatsbyImageData
  }
}

const bannerBreadcrumbs = [
  routesObject.disabilityHome,
  {
    to: "/disability/services",
    text: "Disability services",
    title: "Return navigation"
  }
]

const StyledList = styled.ul`
  padding-inline-start: 1.5rem;
`

const NdisPlanListItem = ({ item }: { item: string }) => (
  <div className="w-100 w-50-l pr0 pr3-l">
    <div className="mt3 mb0 br2 b-lwb-grey-light ba pr4 pr0-l">
      <StyledList>
        {[item].map((text, i) => (
          <TickListItem
            key={i}
            text={text}
            className={i === 0 ? "w-90-ns" : "mt4 w-90-ns"}
            textClasses="pt0 pt1-ns"
          />
        ))}
      </StyledList>
    </div>
  </div>
)

const DailyLivingSkillsContactForm = ({ formId }: { formId: string }) => (
  <DisabilityForm
    formId={formId}
    formKind="disability-home-living"
    formTitle="We're here to help"
    formSubtitle="We just need a few details so our dedicated team can contact you about your enquiry."
    resetFormOnSuccess={false}
  />
)

const CTAButton = ({
  buttonText,
  formId
}: {
  buttonText: string
  formId: string
}) => (
  <Dialog buttonText={buttonText}>
    <DailyLivingSkillsContactForm formId={formId} />
  </Dialog>
)

const DailyLivingSkillsPage = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage,
    lifestyleSupportsEasyEnglish,
    storyMattBarista
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`Daily Living Skills | ${siteMetadata.title}`}
        description="We can help you build on your daily living skills at home and in your local community. "
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={bannerImage}
      />
      <Layout
        fixedCTAContact={
          <DailyLivingSkillsContactForm formId="daily-living-skill-contact-form-fixed-cta" />
        }
      >
        <Banner
          title="Daily living skills"
          subTitle="We can support you at home, in your local community, and help you build on your daily living skills."
          breadcrumbs={bannerBreadcrumbs}
          image={bannerImage}
        />
        <Container>
          <Row className="flex-wrap relative">
            <Box className="w-100 w-ten-twelfths-l layout-gap">
              <Heading size={2}>
                What kind of tasks can you help me with?
              </Heading>
              <p>
                Our range of lifestyle supports help you build on your daily
                living skills, at home and out-and-about in the community. We’ll
                help you with whatever you need or want to do, including trying
                new things.
              </p>
              <p>Tasks, activities and skills we could partner on include:</p>
              <ul className="mv4 pl1 flex flex-wrap w-80-l">
                {[
                  "Joining community clubs, activities and programs",
                  "Personal care",
                  "Learning employable skills",
                  "Looking after your health and fitness",
                  "Buying groceries",
                  "Household chores",
                  "Paying bills and budgeting"
                ].map((text, i) => (
                  <TickListItem
                    key={i}
                    text={text}
                    className={i <= 1 ? "mt0 w-50-ns" : "mt3 w-50-ns"}
                  />
                ))}
              </ul>
              <p className="mb4">
                All our supports will be tailored to you. Together, we’ll help
                you feel more independent, while achieving your goals, being
                creative and having a good time.
              </p>
              <CTAButton
                buttonText="Get Started"
                formId="disability-get-started"
              />
              <PdfLink
                className="mt4"
                to={lifestyleSupportsEasyEnglish.edges[0].node.publicURL}
                title="Easy English – Lifestyle supports at Life Without Barriers"
              />
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row>
              <Box className="w-100 w-ten-twelfths-l pr6-l">
                <Heading size={2}>Where are these supports available?</Heading>
                <p>
                  Our daily living skills and lifestyle-based supports are
                  available in every state and territory.
                </p>
                <p>
                  It’s tailored to you, and we’ll support you in a way that
                  suits your lifestyle. That can include meeting at your home,
                  at local services, or getting together with a group. The
                  amount of time we spend together each week or month is up to
                  you – it’s about finding what works for you.....
                </p>
                <div className="w-80-l pa4 mt4 bg-lwb-theme-medium br3">
                  <LocationModal small={true} currentUrl={location.pathname} />
                </div>
              </Box>
            </Row>
          </Container>
        </Section>
        <Container>
          <Row className="flex-wrap">
            <Box className="layout-readable">
              <Heading size={2}>Who will I be partnered with?</Heading>
              <p>
                Our team will work with you to identify supports that help you
                achieve your goals. You’ll be partnered with a trained
                Disability Support Worker who has experience that aligns with
                your goals, needs and interests.
              </p>
              <p>
                Together, you’ll further develop your daily living skills,
                achieve your goals and get involved in your community in a way
                that’s interesting and enjoyable for you.
              </p>
            </Box>
            <Box className="w-100 mt5">
              <Story
                storyType="Real Stories"
                quoteText="Seeing him smile, doing something that he loves makes it all worth it. He’s opened up 100% and it’s given him a goal he wants to achieve."
                quoteFooter="Candice, Matt’s Support Worker."
                image={storyMattBarista}
              >
                <p className="mb4">
                  Matt’s passion is coffee and he has a dream of opening his own
                  café. So, when Matt’s support team began working with him,
                  they found ways to get him closer to living his dream. With
                  our daily living supports, Matt developed his barista skills
                  and completed some local training. Then, after a coffee
                  machine was generously donated to the local community centre,
                  Matt began operating his own coffee shop one morning a week!
                  On Fridays, he serves coffee to Life Without Barriers staff,
                  clients and the general public, while continuing to boost his
                  skills in other ways.
                </p>
                <CTAButton
                  buttonText="I'm Interested"
                  formId="disability-i-am-interested"
                />
              </Story>
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row>
              <Box className="layout-readable">
                <Heading size={2}>Do you offer day programs?</Heading>
                <p>
                  We offer a range of day programs across Australian Capital
                  Territory, New South Wales, Northern Territory, and Tasmania
                  that involve fun, educational and skill-developing activities,
                  such as:
                </p>
                <ul>
                  <li>Art programs</li>
                  <li>Cooking and gardening</li>
                  <li>Outdoor games, gentle exercise and sports programs</li>
                  <li>Technology skill building, drama and music programs</li>
                  <li>
                    Local community activities, such as going to the library,
                    movies or volunteering.
                  </li>
                </ul>
                <p className="mb4">
                  If you’d like to learn more, please get in touch via our
                  online enquiry form.
                </p>
                <LinkButton
                  title="Find a program"
                  to="/disability/services/social-and-community-participation"
                >
                  Find a program
                </LinkButton>
              </Box>
            </Row>
          </Container>
        </Section>
        <Container>
          <Row className="flex-wrap">
            <Box>
              <Heading size={2}>Is this service in my NDIS plan?</Heading>
              <p>
                These are the support categories in your{" "}
                <Link className="black fw4" to="/disability/ndis/">
                  NDIS plan
                </Link>{" "}
                that you can use:
              </p>
              <div className="flex flex-wrap mt2">
                <NdisPlanListItem item="Assistance with Social and Community Participation (Core Supports)" />
                <NdisPlanListItem item="Increased Social & Community Participation (Capacity Building Supports)" />
                <NdisPlanListItem item="Improved Daily Living Skills (Capacity Building Supports)" />
                <NdisPlanListItem item="Transport (Core Supports)" />
                <NdisPlanListItem item="Assistance with Daily Life (Core Supports)" />
              </div>
            </Box>
          </Row>
        </Container>
        <Section background="bg-hex-image">
          <Container>
            <Row>
              <Box>
                <NavigationTiles
                  heading={RelatedPagesHeading}
                  currentPathname={location.pathname}
                  pages={[
                    SupportCoordinationNoIcon,
                    SupportedIndependentLivingNoIcon,
                    HealthAndWellbeingNoIcon
                  ]}
                />
              </Box>
            </Row>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerImage: file(
      relativePath: { regex: "/daily-living-skills-banner.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
    storyMattBarista: file(
      relativePath: { regex: "/matt-barista-training.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
    lifestyleSupportsEasyEnglish: allFile(
      filter: { name: { eq: "LWB-EasyE-Lifestyle-Supports" } }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`

export default DailyLivingSkillsPage
